exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-amazon-s-3-pricing-js": () => import("./../../../src/pages/amazon/s3/pricing.js" /* webpackChunkName: "component---src-pages-amazon-s-3-pricing-js" */),
  "component---src-pages-aws-amazon-cloud-storage-js": () => import("./../../../src/pages/aws/amazon-cloud-storage.js" /* webpackChunkName: "component---src-pages-aws-amazon-cloud-storage-js" */),
  "component---src-pages-aws-amazon-object-storage-js": () => import("./../../../src/pages/aws/amazon-object-storage.js" /* webpackChunkName: "component---src-pages-aws-amazon-object-storage-js" */),
  "component---src-pages-aws-amazon-storage-cost-per-month-js": () => import("./../../../src/pages/aws/amazon-storage-cost-per-month.js" /* webpackChunkName: "component---src-pages-aws-amazon-storage-cost-per-month-js" */),
  "component---src-pages-aws-amazon-storage-costs-js": () => import("./../../../src/pages/aws/amazon-storage-costs.js" /* webpackChunkName: "component---src-pages-aws-amazon-storage-costs-js" */),
  "component---src-pages-aws-amazon-storage-discounts-js": () => import("./../../../src/pages/aws/amazon-storage-discounts.js" /* webpackChunkName: "component---src-pages-aws-amazon-storage-discounts-js" */),
  "component---src-pages-aws-amazon-storage-js": () => import("./../../../src/pages/aws/amazon-storage.js" /* webpackChunkName: "component---src-pages-aws-amazon-storage-js" */),
  "component---src-pages-aws-amazon-storage-pricing-js": () => import("./../../../src/pages/aws/amazon-storage-pricing.js" /* webpackChunkName: "component---src-pages-aws-amazon-storage-pricing-js" */),
  "component---src-pages-aws-amazon-storage-rates-js": () => import("./../../../src/pages/aws/amazon-storage-rates.js" /* webpackChunkName: "component---src-pages-aws-amazon-storage-rates-js" */),
  "component---src-pages-aws-amazon-storage-resellers-js": () => import("./../../../src/pages/aws/amazon-storage-resellers.js" /* webpackChunkName: "component---src-pages-aws-amazon-storage-resellers-js" */),
  "component---src-pages-aws-aws-cloud-storage-js": () => import("./../../../src/pages/aws/aws-cloud-storage.js" /* webpackChunkName: "component---src-pages-aws-aws-cloud-storage-js" */),
  "component---src-pages-aws-aws-object-storage-cheap-js": () => import("./../../../src/pages/aws/aws-object-storage-cheap.js" /* webpackChunkName: "component---src-pages-aws-aws-object-storage-cheap-js" */),
  "component---src-pages-aws-aws-object-storage-js": () => import("./../../../src/pages/aws/aws-object-storage.js" /* webpackChunkName: "component---src-pages-aws-aws-object-storage-js" */),
  "component---src-pages-aws-aws-storage-cheap-js": () => import("./../../../src/pages/aws/aws-storage-cheap.js" /* webpackChunkName: "component---src-pages-aws-aws-storage-cheap-js" */),
  "component---src-pages-aws-aws-storage-cost-per-month-js": () => import("./../../../src/pages/aws/aws-storage-cost-per-month.js" /* webpackChunkName: "component---src-pages-aws-aws-storage-cost-per-month-js" */),
  "component---src-pages-aws-aws-storage-costs-js": () => import("./../../../src/pages/aws/aws-storage-costs.js" /* webpackChunkName: "component---src-pages-aws-aws-storage-costs-js" */),
  "component---src-pages-aws-aws-storage-discounts-js": () => import("./../../../src/pages/aws/aws-storage-discounts.js" /* webpackChunkName: "component---src-pages-aws-aws-storage-discounts-js" */),
  "component---src-pages-aws-aws-storage-js": () => import("./../../../src/pages/aws/aws-storage.js" /* webpackChunkName: "component---src-pages-aws-aws-storage-js" */),
  "component---src-pages-aws-aws-storage-pricing-js": () => import("./../../../src/pages/aws/aws-storage-pricing.js" /* webpackChunkName: "component---src-pages-aws-aws-storage-pricing-js" */),
  "component---src-pages-aws-aws-storage-rates-js": () => import("./../../../src/pages/aws/aws-storage-rates.js" /* webpackChunkName: "component---src-pages-aws-aws-storage-rates-js" */),
  "component---src-pages-aws-aws-storage-reseller-js": () => import("./../../../src/pages/aws/aws-storage-reseller.js" /* webpackChunkName: "component---src-pages-aws-aws-storage-reseller-js" */),
  "component---src-pages-aws-aws-storage-savings-js": () => import("./../../../src/pages/aws/aws-storage-savings.js" /* webpackChunkName: "component---src-pages-aws-aws-storage-savings-js" */),
  "component---src-pages-aws-cloud-s-3-js": () => import("./../../../src/pages/aws/cloud-s3.js" /* webpackChunkName: "component---src-pages-aws-cloud-s-3-js" */),
  "component---src-pages-aws-cloud-storage-pricing-js": () => import("./../../../src/pages/aws-cloud-storage-pricing.js" /* webpackChunkName: "component---src-pages-aws-cloud-storage-pricing-js" */),
  "component---src-pages-aws-discount-amazon-storage-js": () => import("./../../../src/pages/aws/discount-amazon-storage.js" /* webpackChunkName: "component---src-pages-aws-discount-amazon-storage-js" */),
  "component---src-pages-aws-discount-aws-storage-js": () => import("./../../../src/pages/aws/discount-aws-storage.js" /* webpackChunkName: "component---src-pages-aws-discount-aws-storage-js" */),
  "component---src-pages-aws-discount-s-3-storage-js": () => import("./../../../src/pages/aws/discount-s3-storage.js" /* webpackChunkName: "component---src-pages-aws-discount-s-3-storage-js" */),
  "component---src-pages-aws-discounted-amazon-storage-js": () => import("./../../../src/pages/aws/discounted-amazon-storage.js" /* webpackChunkName: "component---src-pages-aws-discounted-amazon-storage-js" */),
  "component---src-pages-aws-discounted-aws-storage-js": () => import("./../../../src/pages/aws/discounted-aws-storage.js" /* webpackChunkName: "component---src-pages-aws-discounted-aws-storage-js" */),
  "component---src-pages-aws-discounted-s-3-storage-js": () => import("./../../../src/pages/aws/discounted-s3-storage.js" /* webpackChunkName: "component---src-pages-aws-discounted-s-3-storage-js" */),
  "component---src-pages-aws-s-3-blob-storage-js": () => import("./../../../src/pages/aws/s3-blob-storage.js" /* webpackChunkName: "component---src-pages-aws-s-3-blob-storage-js" */),
  "component---src-pages-aws-s-3-cloud-storage-js": () => import("./../../../src/pages/aws/s3-cloud-storage.js" /* webpackChunkName: "component---src-pages-aws-s-3-cloud-storage-js" */),
  "component---src-pages-aws-s-3-current-prices-js": () => import("./../../../src/pages/aws/s3/current-prices.js" /* webpackChunkName: "component---src-pages-aws-s-3-current-prices-js" */),
  "component---src-pages-aws-s-3-object-storage-js": () => import("./../../../src/pages/aws/s3-object-storage.js" /* webpackChunkName: "component---src-pages-aws-s-3-object-storage-js" */),
  "component---src-pages-aws-s-3-pricing-js": () => import("./../../../src/pages/aws/s3/pricing.js" /* webpackChunkName: "component---src-pages-aws-s-3-pricing-js" */),
  "component---src-pages-aws-s-3-save-js": () => import("./../../../src/pages/aws/s3/save.js" /* webpackChunkName: "component---src-pages-aws-s-3-save-js" */),
  "component---src-pages-aws-s-3-savings-js": () => import("./../../../src/pages/aws-s3-savings.js" /* webpackChunkName: "component---src-pages-aws-s-3-savings-js" */),
  "component---src-pages-aws-s-3-storage-cost-per-month-js": () => import("./../../../src/pages/aws/s3-storage-cost-per-month.js" /* webpackChunkName: "component---src-pages-aws-s-3-storage-cost-per-month-js" */),
  "component---src-pages-aws-s-3-storage-costs-js": () => import("./../../../src/pages/aws/s3-storage-costs.js" /* webpackChunkName: "component---src-pages-aws-s-3-storage-costs-js" */),
  "component---src-pages-aws-s-3-storage-discount-js": () => import("./../../../src/pages/aws/s3-storage-discount.js" /* webpackChunkName: "component---src-pages-aws-s-3-storage-discount-js" */),
  "component---src-pages-aws-s-3-storage-discounts-js": () => import("./../../../src/pages/aws/s3-storage-discounts.js" /* webpackChunkName: "component---src-pages-aws-s-3-storage-discounts-js" */),
  "component---src-pages-aws-s-3-storage-price-js": () => import("./../../../src/pages/aws/s3-storage-price.js" /* webpackChunkName: "component---src-pages-aws-s-3-storage-price-js" */),
  "component---src-pages-aws-s-3-storage-pricing-js": () => import("./../../../src/pages/aws/s3-storage-pricing.js" /* webpackChunkName: "component---src-pages-aws-s-3-storage-pricing-js" */),
  "component---src-pages-aws-s-3-storage-rates-js": () => import("./../../../src/pages/aws/s3-storage-rates.js" /* webpackChunkName: "component---src-pages-aws-s-3-storage-rates-js" */),
  "component---src-pages-azure-blob-storage-pricing-1-js": () => import("./../../../src/pages/azure-blob-storage-pricing/1.js" /* webpackChunkName: "component---src-pages-azure-blob-storage-pricing-1-js" */),
  "component---src-pages-azure-blob-storage-pricing-current-pricing-js": () => import("./../../../src/pages/azure-blob-storage-pricing/current-pricing.js" /* webpackChunkName: "component---src-pages-azure-blob-storage-pricing-current-pricing-js" */),
  "component---src-pages-azure-blob-storage-pricing-js": () => import("./../../../src/pages/azure/blob-storage/pricing.js" /* webpackChunkName: "component---src-pages-azure-blob-storage-pricing-js" */),
  "component---src-pages-azure-blob-storage-save-js": () => import("./../../../src/pages/azure/blob-storage/save.js" /* webpackChunkName: "component---src-pages-azure-blob-storage-save-js" */),
  "component---src-pages-azure-pricing-js": () => import("./../../../src/pages/azure-pricing.js" /* webpackChunkName: "component---src-pages-azure-pricing-js" */),
  "component---src-pages-azure-storage-pricing-js": () => import("./../../../src/pages/azure-storage-pricing.js" /* webpackChunkName: "component---src-pages-azure-storage-pricing-js" */),
  "component---src-pages-azure-storage-savings-js": () => import("./../../../src/pages/azure-storage-savings.js" /* webpackChunkName: "component---src-pages-azure-storage-savings-js" */),
  "component---src-pages-azureblob-js": () => import("./../../../src/pages/azureblob.js" /* webpackChunkName: "component---src-pages-azureblob-js" */),
  "component---src-pages-blob-storage-js": () => import("./../../../src/pages/blob-storage.js" /* webpackChunkName: "component---src-pages-blob-storage-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-cloud-storage-current-pricing-js": () => import("./../../../src/pages/cloud-storage/current-pricing.js" /* webpackChunkName: "component---src-pages-cloud-storage-current-pricing-js" */),
  "component---src-pages-cloud-storage-savings-js": () => import("./../../../src/pages/cloud-storage-savings.js" /* webpackChunkName: "component---src-pages-cloud-storage-savings-js" */),
  "component---src-pages-cloud-storage-search-js": () => import("./../../../src/pages/cloud-storage-search.js" /* webpackChunkName: "component---src-pages-cloud-storage-search-js" */),
  "component---src-pages-cloud-storage-search-results-js": () => import("./../../../src/pages/cloud-storage-search-results.js" /* webpackChunkName: "component---src-pages-cloud-storage-search-results-js" */),
  "component---src-pages-compare-and-save-on-cloud-storage-pricing-js": () => import("./../../../src/pages/compare-and-save-on-cloud-storage-pricing.js" /* webpackChunkName: "component---src-pages-compare-and-save-on-cloud-storage-pricing-js" */),
  "component---src-pages-compare-aws-s-3-azure-blob-google-comparison-js": () => import("./../../../src/pages/compare/aws-s3-azure-blob-google-comparison.js" /* webpackChunkName: "component---src-pages-compare-aws-s-3-azure-blob-google-comparison-js" */),
  "component---src-pages-compare-cloud-storage-prices-js": () => import("./../../../src/pages/compare-cloud-storage-prices.js" /* webpackChunkName: "component---src-pages-compare-cloud-storage-prices-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-enterprise-current-pricing-js": () => import("./../../../src/pages/enterprise/current-pricing.js" /* webpackChunkName: "component---src-pages-enterprise-current-pricing-js" */),
  "component---src-pages-gcp-storage-current-pricing-js": () => import("./../../../src/pages/gcp-storage/current-pricing.js" /* webpackChunkName: "component---src-pages-gcp-storage-current-pricing-js" */),
  "component---src-pages-gcp-storage-discounts-js": () => import("./../../../src/pages/gcp-storage/discounts.js" /* webpackChunkName: "component---src-pages-gcp-storage-discounts-js" */),
  "component---src-pages-gcp-storage-pricing-js": () => import("./../../../src/pages/gcp-storage-pricing.js" /* webpackChunkName: "component---src-pages-gcp-storage-pricing-js" */),
  "component---src-pages-get-a-quote-js": () => import("./../../../src/pages/get-a-quote.js" /* webpackChunkName: "component---src-pages-get-a-quote-js" */),
  "component---src-pages-google-cloud-storage-savings-js": () => import("./../../../src/pages/google-cloud-storage-savings.js" /* webpackChunkName: "component---src-pages-google-cloud-storage-savings-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-law-firms-current-pricing-js": () => import("./../../../src/pages/law-firms/current-pricing.js" /* webpackChunkName: "component---src-pages-law-firms-current-pricing-js" */),
  "component---src-pages-microsoft-azure-blob-pricing-js": () => import("./../../../src/pages/microsoft-azure-blob-pricing.js" /* webpackChunkName: "component---src-pages-microsoft-azure-blob-pricing-js" */),
  "component---src-pages-microsoft-azure-storage-pricing-js": () => import("./../../../src/pages/microsoft-azure-storage-pricing.js" /* webpackChunkName: "component---src-pages-microsoft-azure-storage-pricing-js" */),
  "component---src-pages-msp-free-leads-js": () => import("./../../../src/pages/msp/free-leads.js" /* webpackChunkName: "component---src-pages-msp-free-leads-js" */),
  "component---src-pages-onboard-js": () => import("./../../../src/pages/onboard.js" /* webpackChunkName: "component---src-pages-onboard-js" */),
  "component---src-pages-onboardmsp-js": () => import("./../../../src/pages/onboardmsp.js" /* webpackChunkName: "component---src-pages-onboardmsp-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-s-3-cloud-storage-pricing-1-js": () => import("./../../../src/pages/s3-cloud-storage-pricing/1.js" /* webpackChunkName: "component---src-pages-s-3-cloud-storage-pricing-1-js" */),
  "component---src-pages-s-3-cloud-storage-pricing-2-js": () => import("./../../../src/pages/s3-cloud-storage-pricing/2.js" /* webpackChunkName: "component---src-pages-s-3-cloud-storage-pricing-2-js" */),
  "component---src-pages-s-3-cloud-storage-pricing-3-js": () => import("./../../../src/pages/s3-cloud-storage-pricing/3.js" /* webpackChunkName: "component---src-pages-s-3-cloud-storage-pricing-3-js" */),
  "component---src-pages-s-3-storage-savings-js": () => import("./../../../src/pages/s3-storage-savings.js" /* webpackChunkName: "component---src-pages-s-3-storage-savings-js" */),
  "component---src-pages-save-on-google-cloud-storage-js": () => import("./../../../src/pages/save-on-google-cloud-storage.js" /* webpackChunkName: "component---src-pages-save-on-google-cloud-storage-js" */),
  "component---src-pages-small-business-current-pricing-js": () => import("./../../../src/pages/small-business/current-pricing.js" /* webpackChunkName: "component---src-pages-small-business-current-pricing-js" */),
  "component---src-pages-welcome-careers-js": () => import("./../../../src/pages/welcome/careers.js" /* webpackChunkName: "component---src-pages-welcome-careers-js" */),
  "component---src-pages-welcome-contact-js": () => import("./../../../src/pages/welcome/contact.js" /* webpackChunkName: "component---src-pages-welcome-contact-js" */),
  "component---src-pages-welcome-frontpage-js": () => import("./../../../src/pages/welcome/frontpage.js" /* webpackChunkName: "component---src-pages-welcome-frontpage-js" */),
  "component---src-pages-welcome-home-js": () => import("./../../../src/pages/welcome/home.js" /* webpackChunkName: "component---src-pages-welcome-home-js" */),
  "component---src-pages-welcome-howitworks-js": () => import("./../../../src/pages/welcome/howitworks.js" /* webpackChunkName: "component---src-pages-welcome-howitworks-js" */),
  "component---src-pages-welcome-privacy-js": () => import("./../../../src/pages/welcome/privacy.js" /* webpackChunkName: "component---src-pages-welcome-privacy-js" */),
  "component---src-pages-welcome-start-js": () => import("./../../../src/pages/welcome/start.js" /* webpackChunkName: "component---src-pages-welcome-start-js" */),
  "component---src-pages-welcome-whywindrate-js": () => import("./../../../src/pages/welcome/whywindrate.js" /* webpackChunkName: "component---src-pages-welcome-whywindrate-js" */),
  "component---src-pages-why-windrate-js": () => import("./../../../src/pages/why-windrate.js" /* webpackChunkName: "component---src-pages-why-windrate-js" */)
}

